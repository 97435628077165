





















import ConversionFactorType from "@/components/ConversionFactorType.vue";
import { IConversionFactorType } from "@/models/ConversionFactorType";
import adminService from "@/services/AdminService";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConversionFactorType,
  },
})
export default class ConversionFactors extends Vue {
  public conversionFactorTypes: IConversionFactorType[] = [];
  public factorAsPercentage: Map<string, boolean> = new Map();

  // Map containing a list of valid scopes for each conversion factor type
  public validScopes: Map<string, string[]> = new Map();

  public async mounted() {
    const conversionFactorTypes = await adminService.getConversionFactorTypes();

    this.conversionFactorTypes = conversionFactorTypes.map(
      (conversionFactorType) => {
        if (conversionFactorType.name === "CO2") {
          conversionFactorType.name = `Emissionsfaktorer CO${
            String.fromCharCode(8322) // 2 as subscript
          }`;
          this.factorAsPercentage.set(conversionFactorType.id, false);
          this.validScopes.set(conversionFactorType.id, ["1", "2", "3"]); // Allow all scopes
        }

        if (conversionFactorType.name === "kWh") {
          conversionFactorType.name = "Beräkningsfaktorer kWh";
          this.factorAsPercentage.set(conversionFactorType.id, false);
          this.validScopes.set(conversionFactorType.id, ["1", "2"]); // Allow scopes 1 and 2
        }

        if (conversionFactorType.name === "Renewable") {
          conversionFactorType.name = "Andel förnybart";
          this.factorAsPercentage.set(conversionFactorType.id, true);
          this.validScopes.set(conversionFactorType.id, ["1", "2"]); // Allow scopes 1 and 2
        }

        return {
          id: conversionFactorType.id,
          name: conversionFactorType.name,
        };
      }
    );
  }
}
